<template>
  <div>
    <TopMenu :menu="menu"/>
    <v-main>
      <router-view 
        :review="menu" 
        :loadingWindow="loadingWindow"
      />
    </v-main>
    <Footer/>
    <Stock/>
    <transition 
      enter-class="scale-enter"
      enter-active-class="scale-enter-active"
      leave-to-class="scale-leave-to"
      leave-active-class="scale-leave-active"
    >
      <v-btn
        v-if="showtop"
        class="to-top"
        fab
        color="#4f8bcf"
        @click="toTop()"
      >
        <v-icon color='#ffffff'>
          mdi-chevron-up
        </v-icon>
      </v-btn>
    </transition>
  </div>
</template>

<script>
import TopMenu from '@/components/TopMenu.vue'
import Footer from '@/components/Footer.vue'
import Stock from '@/components/Stock.vue'
export default {
  components: {TopMenu, Footer, Stock},
  props: {
    loadingWindow: Function
  },
  data: () => ({
    menu: {
      top: 'menu-fixed',
    },
    showtop: false
  }),
  methods: {
    toTop: () => {
      window.scrollTo({top: 0, behavior: 'smooth'})
    },
  },
  created () {
    this.$vuetify.theme.dark = false
    let position = 0
    let topMenuScroll = window.onscroll = () => {
      window.pageYOffset > 1100 ? this.showtop = true : this.showtop = false
      let current_position = window.pageYOffset
      if (current_position < position) {
        if (this.menu.top != 'menu-fixed') {
          this.menu.top = 'menu-fixed'
        }
      } else {
        if (this.menu.top != 'menu-noFixed') {
          if(document.body.classList.contains('m-active')) {
            this.menu.top = 'menu-fixed'
          } else {
            this.menu.top = 'menu-noFixed'
          }
        }
      }
      position = current_position
    }
  },
}
</script>

<style lang="scss" scoped>
  .to-top {
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 10;
    outline: none;
  }
</style>
