<template>
  <div>
    <div :class="`top-line-mobile ${menu.top}`">
      <div class="mbtn">
        <v-btn
          text
          dark
          @click="mbtnClick"
        >
          <v-icon left color="#4caf50" v-if="!mbtnActive">mdi-menu</v-icon>
          <v-icon left color="#4caf50" v-if="mbtnActive">mdi-close-box</v-icon>
          Меню
        </v-btn>
      </div>
      <TelSocBtn/>
    </div>
    <div :class="`top-line ${menu.top}`">
      <div class="top-line-wrap grid app-container">
        <div class="logo grid" @click="home()">
          <v-img
            src="img/logo/logo_s.png"
            max-width="190"
          ></v-img>
          <div class="logo-txt">
            Автошкола
            <span>Зеленый Свет</span>
          </div>
        </div>
        <div class="menu grid">
          <ul>
            <li
              v-for="(item, i) in li" 
              :key="i"
              @mouseover="hover1"
              @mouseout="hover2"
              :class="cls"
              @click="push()"
            ><router-link :to="item.id != '' ? {path: item.path, hash: '#' + item.id} : {path: item.path}">{{item.link}}</router-link></li>
          </ul>
        </div>
        <TelSocBtn/>
      </div>
    </div>
    <div class="top-menu-block">
      <ul>
        <li
          v-for="(item, i) in li" 
          :key="i"
          @click="push()"
        ><router-link :to="item.id != '' ? {path: item.path, hash: '#' + item.id} : {path: item.path}">{{item.link}}</router-link></li>
      </ul>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import TelSocBtn from '@/components/TelSocBtn.vue'
export default {
  components: {
    TelSocBtn
  },
  props: {
    menu: Object
  },
  data: () => ({
    cls: '',
    mbtnActive: false,
    li: [
      {id: 'home', link: 'главная', path: '/'},
      {id: 'cost', link: 'цены', path: '/'},
      {id: '', link: 'курсы', path: '/courses'},
      {id: 'avto', link: 'автопарк', path: '/'},
      {id: 'cont', link: 'контакты', path: '/'},
      {id: '', link: 'отзывы', path: '/reviews'},
    ]
  }),
  computed: {
    ...mapGetters(['getContent'])
  },
  methods: {
    mbtnClick() {
      this.mbtnActive = !this.mbtnActive
      document.body.classList.toggle('m-active')
    },
    push() {
      let t = 0
      if(document.body.classList.contains('m-active')) {
        t = 350
        this.mbtnClick()
      } else {
        t = 0
      }
      setTimeout(() => {
        if (this.$route.path == '/') {
          let block = document.querySelector(`${this.$route.hash}`)
          if(this.$route.hash == '#home') {
            window.scrollTo({top: 0, behavior: 'smooth'})
          } 
          if(block != null) {
            block.scrollIntoView({block: this.getContent.scrollBlock, behavior: this.getContent.scrollBehavior})
          }
        }
      }, t);
    },
    home() {
      this.$route.path == '/' ? window.scrollTo({top: 0, behavior: 'smooth'}) : this.$router.push('/')
    },
    
    hover1() {
      this.cls = 'optc'
    },
    hover2() {
      this.cls = ''
    },
    
  },
}
</script>

<style lang="scss" scoped>
  .top-line-mobile {
    display: none;
    position: fixed;
    height: 50px;
    z-index: 7;
    background-color: rgba(35, 35, 35, .9);
    width: 100%;
    transition: all .3s;
  }
  .top-line {
    height: 79px;
    width: 100%;
    background-color: rgba(0,0,0,.9);
    display: flex;
    position: fixed;
    left: 0;
    z-index: 5;
  }
  .top-line-wrap {
    grid-template-columns: repeat(3, 1fr);
    position: relative;
  }
  .logo {
    color: #ffffff;
    font-weight: 700;
    align-items: center;
    justify-items: center;
    cursor: pointer;
    span {
      color: greenyellow;
    }
  }
  .logo-txt {
    margin-top: -10px;
  }
  .menu {
    align-items: center;
    justify-items: center;
    color: #ffffff;
    user-select: none;
    ul {
      padding-left: 0;
      li {
        display: inline;
        cursor: pointer;
        text-transform: uppercase;
        transition: all .3s;
        &:hover {
          opacity: 1;
        }
        a {
          text-decoration: none;
          color: #ffffff;
          padding: 0 10px;
          outline: none;
        }
      }
    }
    .optc {
      opacity: .5;
    }
  }
  
  .menu-fixed {
    top: 0;
    opacity: 1;
    animation-name: menu_show;
    animation-duration: .3s;
  }
  @keyframes menu_show {
    0% {
      opacity: 0;
      top: -79px;
    }
    100% {
      opacity: 1;
      top: 0;
    }
  }
  .menu-noFixed {
    top: -79px;
    opacity: 0;
    animation-name: menu_hide;
    animation-duration: .3s;
  }
  @keyframes menu_hide {
    0% {
      opacity: 1;
      top: 0;
    }
    100% {
      opacity: 0;
      top: -79px;
    }
  }
  .mbtn {
    display: flex;
    align-items: center;
  }
  .top-menu-block {
    display: grid;
    background-color: #232323;
    position: fixed;
    height: 100vh;
    width: 270px;
    z-index: 10;
    transform: translateX(-270px);
    transition: all .3s;
    align-content: center;
    justify-content: center;
    ul {
      text-transform: uppercase;
      li {
        list-style-type: none;
        cursor: pointer;
        margin: 25px 0;
        a {
          color: #ffffff;
          text-decoration: none;
        }
        i {
          margin-left: -25px;
        }
      }
    }
  }
  @media only screen and (max-width: 870px) {
    .top-line {
      display: none;
    }
    .top-line-mobile {
      display: grid;
      grid-template-columns: 1fr 5fr;
    }
  }
</style>