import axios from 'axios'
export default {
  state: {
    city: [],
    address: [],
    phone: [],
    social: []
  },
  mutations: {
    mutCity(state, payload) {
      state.city = []
      state.city = payload
    },

    mutAddress(state, payload) {
      state.address = []
      state.address = payload
    },

    mutPhone(state, payload) {
      state.phone = []
      state.phone = payload
    },

    mutSocial(state, payload) {
      state.social = []
      state.social = payload
    }
  },
  actions: {
    async city({commit, rootState}) {
      let response = await axios({
        url: `${rootState.host}/city`,
        method: 'POST'
      })
      let result = await response
      commit('mutCity', result.data)
    },

    async address({commit, rootState}) {
      let response = await axios({
        url: `${rootState.host}/address`,
        method: 'POST'
      })
      let result = await response
      commit('mutAddress', result.data)
    },

    async phone({commit, rootState}) {
      let response = await axios({
        url: `${rootState.host}/phone`,
        method: 'POST'
      })
      let result = await response
      commit('mutPhone', result.data)
    },

    async social({commit, rootState}) {
      let response = await axios({
        url: `${rootState.host}/social`,
        method: 'POST'
      })
      let result = await response
      commit('mutSocial', result.data)
    },

    async deletePhone({dispatch, rootState}, payload) {
      let response = await axios({
        url: `${rootState.host}/deletePhone`,
        method: 'POST',
        data: payload
      })
      let result = await response
      dispatch('phone')
      return result.data
    },

    async savePhone({dispatch, rootState}, payload) {
      let response = await axios({
        url: `${rootState.host}/savePhone`,
        method: 'POST',
        data: payload
      })
      let result = await response
      dispatch('phone')
      return result.data
    },

    async saveAddress({dispatch, rootState}, payload) {
      let response = await axios({
        url: `${rootState.host}/saveAddress`,
        method: 'POST',
        data: payload
      })
      let result = await response
      dispatch('address')
      return result.data
    },

    async updateAddress({dispatch, rootState}, payload) {
      let response = await axios({
        url: `${rootState.host}/updateAddress`,
        method: 'POST',
        data: payload
      })
      let result = await response
      dispatch('address')
      return result.data
    },

    async deleteAddress({dispatch, rootState}, payload) {
      let response = await axios({
        url: `${rootState.host}/deleteAddress`,
        method: 'POST',
        data: payload
      })
      let result = await response
      dispatch('address')
      return result.data
    },
  },
  getters: {
    getCity: state => {
      let arr = []
      state.city.map(c => {
        arr.push({text: c.city, value: c.id})
      })
      return arr
    },
    getAddress: state => state.address,
    getPhone: state => state.phone,
    getSocial: state => state.social
  }
}