import Vue from 'vue'
import App from './App.vue'
import '@mdi/font/css/materialdesignicons.css'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import Message from '@/components/global/Message'
import Loading from '@/components/global/Loading'
import dateFilter from '@/components/filters/date.filter'
import htmlFilter from '@/components/filters/html.filter'
import clickOutside from '@/components/directives/outside.js'
import VueThreeSixty from 'vue-360'
import 'vue-360/dist/css/style.css'
import Lightbox from 'vue-easy-lightbox'

Vue.config.productionTip = false
Vue.component('message', Message)
Vue.component('loading', Loading)
Vue.filter('date', dateFilter)
Vue.filter('html', htmlFilter)
Vue.directive('click-outside', clickOutside)
Vue.use(VueThreeSixty)
Vue.use(Lightbox)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
