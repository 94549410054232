<template>
  <div :class="`stock ${cls}`" v-if="getContent.stock != ''">
    <div class="wrap">
      <div class="close" @click="close()">
        <v-img class="ico" src="img/icons/close.png"></v-img>
      </div>
    </div>
    <v-img class="img" :src="getContent.stock"></v-img>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  data: () => ({
    cls: 'closed'
  }),
  computed: {
    ...mapGetters(['getContent'])
  },
  methods: {
    close() {
      this.cls = 'closed'
    }
  },
  async mounted() {
    setTimeout(() => {
      this.cls = 'open'
    }, 2500);
  }
}
</script>

<style lang="scss" scoped>
  .open {
    display: block;
  }
  .closed {
    display: none;
  }
  .stock {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    
    .wrap {
      position: relative;
      .close {
        position: absolute;
        width: 30px;
        height: 30px;
        background-color: #ffffff;
        border-radius: 50%;
        top: 5px;
        right: 5px;
        z-index: 1;
        cursor: pointer;
      }
      
    }
    .img {
        max-width: 300px;
        border-radius: 3px;
      }
  }
</style>
