<template>
  <v-dialog
      v-model="dialog"
      persistent
      width="300"
    >
      <v-card
        color="#4caf50"
        dark
      >
        <v-card-text>
          Пожалуйста подождите...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false
  }),
  methods: {
    loadingWindow(val) {
      this.dialog = val
    }
  },
  mounted() {
    this.$emit('loadingWindow', this.loadingWindow)
  }
}
</script>