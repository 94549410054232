<template>
  <v-app>
    <div class="wrapper">
      <router-view :messageOn="messageOn"/>
    </div>
  </v-app>
</template>
<script>
  import {mapActions} from 'vuex'
  export default {
    props: {
      messageOn: Function
    },
  }
</script>
<style lang="scss" scoped>
  .wrapper {
    background-color: #202020;
    height: 100vh;
    display: grid;
    justify-items: center;
    align-items: center;
  }
</style>