<template>
  <div>
    <v-snackbar
      v-model="snackbar"
      :color="color"
      :timeout="timeout"
      right
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          icon
          v-bind="attrs"
          @click="snackbar = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
  export default {
    data: () => ({
      snackbar: false,
      timeout: 4000,
      text: '[Ошибка сообщения]',
      color: '#FF0000'
    }),
    methods: {
      mess(txt, color) {
        this.snackbar = false
        setTimeout(() => {
          this.text = txt
          txt ? this.text = txt : false
          color ? this.color = color : false
          this.snackbar = true
        }, 100);
      },
    },
    mounted() {
      this.$emit('messageOn', this.mess)
    }
  }
</script>

<style lang="scss" scoped>
  .v-snack {
    font-size: 17px;
  }
</style>