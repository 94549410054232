<template>
  <v-app id="inspire">
    <v-navigation-drawer
      v-model="drawer"
      app
      clipped
    >
      <v-list dense>
        <v-list-item-group
          :mandatory="mandatory"
        >
          <v-list-item 
            v-for="(link, i) in links" 
            :key="i"
            :to="link.url"
          >
            {{link.title}}
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
      app
      clipped-left
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>
        <span style="color: greenyellow; font-weight: bold;">zelsvet.by</span> <span>{{date | date('datetime')}}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div class="username"> <v-icon>mdi-account-circle</v-icon> {{getUser()}} </div>
      <v-btn
        color="#FF4500"
        small
        @click="log_out"
      >Выйти</v-btn>
    </v-app-bar>

    <v-main class="ma-5">
      <router-view 
        :messageOn="messageOn" 
        :loadingWindow="loadingWindow"
      />
    </v-main>

    <v-footer app>
      <span>&copy; 2020</span>
    </v-footer>
  </v-app>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex'
  export default {
     props: {
      messageOn: Function,
      loadingWindow: Function
    },
    data: () => ({
      drawer: null,
      mandatory: true,
      date: new Date(),
      interval: null,
      links: [
        {title: 'Категории', url: '/cost'},
        {title: 'Курсы', url: '/cost-courses'},
        {title: 'Галерея', url: '/files'},
        {title: 'Отзывы', url: '/admreviews'},
        {title: 'Контакты', url: '/contacts'},
        {title: 'Документы', url: '/docs'},
        {title: 'Настройки', url: '/settings'}
      ],
    }),
    methods: {
      ...mapActions(['logout',]),
      ...mapGetters(['isLoggedIn', 'getUser']),
      async log_out() {
        await this.logout()
        if(this.isLoggedIn() == false) {
          this.$router.push({ path: '/admin_zelsvet' })
        }
      },
    },
    created () {
      this.$vuetify.theme.dark = true
    },
    async mounted () {
      this.interval = setInterval(() => {
        this.date = new Date()
      }, 1000);
    },
    beforeDestroy() {
      clearInterval(this.interval)
    }
  }
</script>

<style lang="scss" scoped>
  .username {
    margin-right: 15px;
  }
  .v-list-item {
    padding: 0;
  }
  .v-application {
    a {
      color: #ffffff;
      width: 100%;
      padding: 0 16px;
      line-height: 56px;
      text-decoration: none;
    }
  }
</style>