import axios from 'axios'
export default {
  state: {
    reviews: [],
    reviewuser: {}
  },
  mutations: {
    mutReviews(state, payload) {
      state.reviews = payload
    },

    mutCheckEmail(state, payload) {
      state.reviewuser = {}
      state.reviewuser = payload
    }
  },
  actions: {
    async reviews({commit, rootState}, payload) {
      let response = await axios({
        url: `${rootState.host}/reviews`,
        method: 'POST',
        data: payload
      })
      let result = await response
      commit('mutReviews', result.data)
    },

    async checkEmail({commit, rootState}, payload) {
      let response = await axios({
        url: `${rootState.host}/checkEmail`,
        method: 'POST',
        data: payload
      })
      let result = await response
      commit('mutCheckEmail', result.data)
    },

    async reviewInsert({commit, rootState}, payload) {
      let response = await axios({
        url: `${rootState.host}/reviewInsert`,
        method: 'POST',
        data: payload
      })
      let result = await response
      commit('mutReviews', result.data)
    },
    async responseInsert({commit, rootState}, payload) {
      let response = await axios({
        url: `${rootState.host}/responseInsert`,
        method: 'POST',
        data: payload
      })
      let result = await response
      commit('mutReviews', result.data)
      return result.data
    },
    async responseUpdate({commit, rootState}, payload) {
      let response = await axios({
        url: `${rootState.host}/responseUpdate`,
        method: 'POST',
        data: payload
      })
      let result = await response
      commit('mutReviews', result.data)
      return result.data
    },
    async responseDelete({commit, rootState}, payload) {
      let response = await axios({
        url: `${rootState.host}/responseDelete`,
        method: 'POST',
        data: payload
      })
      let result = await response
      commit('mutReviews', result.data)
      return result.data
    }
  },
  getters: {
    getReviews: state => state.reviews,
    getCheckEmail: state => state.reviewuser
  }
}