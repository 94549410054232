<template>
  <div class="phone grid">
          <div>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mx-2 btn-phonee"
                  fab
                  dark
                  small
                  color="#4caf50"
                  v-bind="attrs"
                  v-on="on"
                  @click="phone = true"
                >
                  <v-icon dark>
                    mdi-phone
                  </v-icon>
                </v-btn>
              </template>
              <span>Наши телефоны</span>
            </v-tooltip>
            <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-2"
                fab
                dark
                small
                color="#5181b8"
                v-bind="attrs"
                v-on="on"
                @click="location('vk')"
              >
                <v-icon dark>
                  mdi-vk
                </v-icon>
              </v-btn>
            </template>
            <span>VKontakte</span>
          </v-tooltip>
          </div>

          <transition name="fade">
            <div class="phone-block" v-if="phone" v-click-outside="hide">
              <div class="phone-block-item" v-for="(city, c) in getCity" :key="c + 'city'">
                <div class="phone-block-city">
                  <v-icon small left>mdi-map-marker-radius</v-icon>
                  {{city.text}}:
                </div>
                <div v-for="(phone, p) in getPhone" :key="p + 'phone'">
                  <div class="phone-block-phone">
                    <v-icon left small v-if="phone.city_id == city.value">mdi-phone</v-icon>
                    <a :href="`tel:${phone.city_id == city.value ? phone.phone : ''}`">{{phone.city_id == city.value ? phone.phone : ''}}</a>
                  </div>
                </div>
              </div>
            </div>
          </transition>

        </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  data: () => ({
    phone: false,
  }),
  computed: {
    ...mapGetters(['getCity', 'getPhone','getSocial'])
  },
  methods: {
    hide() {this.phone = false},
    location(type) {
      this.getSocial.map(s => {
        if(type == 'vk') {
          if(s.type == 'VK') window.open(s.val_)
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
  .phone-block-item {
    &:nth-child(1) {
      margin-bottom: 10px;
    }
  }
  .phone-block-city {
    margin-bottom: 5px;
    color: greenyellow;
    i {
      color: greenyellow;
    }
  }
  .phone-block-phone {
    a {
      color: #ffffff;
      text-decoration: none;
    }
    i {
      color: #FF4500;
    }
  }
  .phone {
    align-items: center;
    justify-items: center;
    position: relative;
    .phone-block {
      position: absolute;
      padding: 20px 25px;
      background-color: rgba(0,0,0,.9);
      color: #ffffff;
      top: 85px;
      z-index: 3;
      border-radius: 3px;
    }
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
    opacity: 0;
  }
  @media only screen and (max-width: 870px) {
    .phone {
      justify-items: end;
    }
  }
</style>