import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import auth from './auth'
import cost from './cost'
import contacts from './contacts'
import reviews from './reviews'
import uploads from './uploads'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    host: '',
    content: [],
  },
  mutations: {
    mutHost(state, payload) {
      state.host = payload.host
    },

    mutContent(state, payload) {
      state.content = []
      state.content = payload
    },
  },
  actions: {
    async host({commit}) {
      let response = await axios({
        method: 'GET',
        url: './json/host.json'
      })
      let result = await response
      commit('mutHost', result.data)
    },

    async content({commit, rootState}) {
      let response = await axios({
        method: 'POST',
        url: `${rootState.host}/content`
      })
      let result = await response
      commit('mutContent', result.data)
    },

    async docpass({rootState}) {
      let response = await axios({
        url: `${rootState.host}/docpass`,
        method: 'POST'
      })
      let result = await response
      return result.data
    }, 

    async docPassUpdate({rootState}, payload) {
      await axios({
        url: `${rootState.host}/docPassUpdate`,
        method: 'POST',
        data: payload
      })
    },

    async checkDocPass({rootState}, payload) {
      let response = await axios({
        url: `${rootState.host}/checkDocPass`,
        method: 'POST',
        responseType:'blob',
        data: payload
      })
      return response
    },

    async __sendMail({rootState}, payload) {
      let response = await axios({
        url: `${rootState.host}/sendMail`,
        method: 'POST',
        data: payload
      })
      let result = await response
      return result.data
    }
  },
  getters: {
    getContent: state => state.content
  },
  modules: {
    auth, cost, contacts, reviews, uploads
  }
})
