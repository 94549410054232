import axios from 'axios'
export default {
  state: {
    images: [],
    images360: [],
    doc: []
  },

  mutations: {
    mutImages(state, payload) {
      state.images = []
      state.images = payload
    },

    mutImages360(state, payload) {
      state.images360 = []
      state.images360 = payload
    },

    mutDoc(state, payload) {
      state.doc = []
      state.doc = payload
    }
  },

  actions: {
    async uploadFiles({commit, dispatch, rootState}, payload) {
      const cat = payload.cat
      let path = 'uploadAutopark'
      let formData = new FormData()
      for (const f of payload.files) {
        formData.set('cat', cat)
        formData.append('files', f)
      }
      let response = await axios({
        url: `${rootState.host}/${path}`,
        method: 'POST',
        headers: {'Content-Type': 'multipart/form-data'},
        data: formData
      })
        let result = await response
        await dispatch('images')
        return result.data
    },

    async deleteFiles({commit, dispatch, rootState}, payload) {
      let response = await axios({
        url: `${rootState.host}/deleteAutopark`,
        method: 'POST',
        data: payload
      })
      let result = await response
      await dispatch('images')
      return result.data
    },

    async images({commit, rootState}) {
      let response = await axios({
        url: `${rootState.host}/images`,
        method: 'POST'
      })
      let result = await response
      commit('mutImages', result.data)
    },

    async doc({rootState, commit}) {
      let response = await axios({
        url: `${rootState.host}/doc`,
        method: 'POST'
      })
      let result = await response
      commit('mutDoc', result.data)
    },

    async uploadDoc({rootState, dispatch}, payload) {
      let formData = new FormData()
      formData.set('name', payload.name)
      formData.set('files', payload.file)
      let response = await axios({
        url: `${rootState.host}/uploadDoc`,
        method: 'POST',
        data: formData
      })
      let result = await response.data
      dispatch('doc')
      return result
    },

    async deleteDoc({rootState, dispatch}, payload) {
      let response = await axios({
        url: `${rootState.host}/deleteDoc`,
        method: 'POST',
        data: payload
      })
      let result = await response.data
      dispatch('doc')
      return result
    }
  },

  getters: {
    getImages: state => state.images,
    getDoc: state => state.doc
	}
}