import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {layout: 'HomeLayout'},
    component: () => import('@/views/Home.vue')
  },
  {
    path: '/admin_zelsvet',
    name: 'login',
    meta: {layout: 'LoginLayout'},
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/files',
    name: 'files',
    meta: {layout: 'AdminLayout', auth: true},
    component: () => import('@/views/Files.vue')
  },
  {
    path: '/admreviews',
    name: 'admreviews',
    meta: {layout: 'AdminLayout', auth: true},
    component: () => import('@/views/AdmReviews.vue')
  },
  {
    path: '/contacts',
    name: 'contacts',
    meta: {layout: 'AdminLayout', auth: true},
    component: () => import('@/views/Contacts.vue')
  },
  {
    path: '/docs',
    name: 'docs',
    meta: {layout: 'AdminLayout', auth: true},
    component: () => import('@/views/Docs.vue')
  },
  {
    path: '/cost',
    name: 'cost',
    meta: {layout: 'AdminLayout', auth: true},
    component: () => import('@/views/Cost.vue')
  },
  {
    path: '/cost-courses',
    name: 'cost-courses',
    meta: {layout: 'AdminLayout', auth: true},
    component: () => import('@/views/CoursesCost.vue')
  },
  {
    path: '/reviews',
    name: 'reviews',
    meta: {layout: 'HomeLayout'},
    component: () => import('@/views/Reviews.vue')
  },
  {
    path: '/courses',
    name: 'courses',
    meta: {layout: 'HomeLayout'},
    component: () => import('@/views/Сourses.vue')
  },
  {
    path: '/settings',
    name: 'settings',
    meta: {layout: 'AdminLayout', auth: true},
    component: () => import('@/views/Settings.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.auth)) {
    store.dispatch('host').then(() => {
      store.dispatch('checkLogin').then(() => {
        if(store.getters.authStatus == 'success') {
          next()
        }
        if(store.getters.authStatus == 'error') {
          next({
            path: '/admin_zelsvet'
          })
        }
      })
    })
  } else {
    next()
  }
})

export default router
