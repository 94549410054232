<template>
  <v-app>
   <div id="app">
      <component 
        :is="layout" 
        :messageOn="messageOn" 
        :loadingWindow="loadingWindow"
      >
        <router-view/>
      </component>
      <message @messageOn="messageOn = $event"></message>
      <loading @loadingWindow="loadingWindow = $event"></loading>
    </div>
  </v-app>
</template>

<script>
import HomeLayout from '@/layouts/HomeLayout'
import LoginLayout from '@/layouts/LoginLayout'
import AdminLayout from '@/layouts/AdminLayout' 
import {mapActions} from 'vuex'
export default {
  name: 'App',
  components: {
    HomeLayout,
    LoginLayout,
    AdminLayout,
  },
  data: () => ({
    messageOn: Function,
    loadingWindow: Function
  }),
  computed: {
    layout() {
      return this.$route.meta.layout
    }
  },
  methods: {
    ...mapActions([
      'host',
      'cost', 
      'city', 
      'address', 
      'phone', 
      'content', 
      'images',
      'social', 
      'doc', 
      'courses'
    ])
  },
  async mounted() {
    await this.host()
    this.content()
    this.cost()
    this.city()
    this.courses()
    this.address()
    this.phone()
    this.social()
    this.images()
    this.doc()
  }
};
</script>

<style lang="scss" scoped>
  @import url('./assets/css/main.scss');
</style>
