<template>
  <div>
    <div class="divider"></div>
    <v-footer
      padless
      absolute
      color="#303036"
      class="footer"
    >
      <v-card
        flat
        tile
        width="100%"
        class="lighten-1 text-center"
        color="#303036"
      >
        <v-card-text>
          <v-btn
            v-for="(item, i) in items"
            :key="i"
            class="mx-4 ico"
            icon
            @click="location(item.name)"
          >
            <v-icon size="24px">
              {{ item.ico }}
            </v-icon>
          </v-btn>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="white--text">
          {{ new Date().getFullYear() }} <v-icon color="#ffffff" small>mdi-copyright</v-icon> <span>ООО «НОВАЯ ДОРОГА»</span>
        </v-card-text>
      </v-card>
    </v-footer>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  data: () => ({
    items: [
      {name: 'home', ico: 'mdi-home'},
      {name: 'vk', ico: 'mdi-vk'},
      /* {name: 'viber', ico: 'mdi-phone-in-talk'}, */
    ]
  }),
  computed: {
    ...mapGetters(['getSocial']),
  },
  methods: {
    scrollTop() {
      if(this.$route.path == '/') {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      } else {
        this.$router.push('/')
      }
    },
    location(type) {
      if(type == 'home') {
        this.scrollTop()
      } else {
        this.getSocial.map(s => {
          if(type == 'vk') {
            if(s.type == 'VK') window.open(s.val_)
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .divider {
    height: 150px;
  }
  .footer {
    .ico {
      color: #878789 !important;
      &:hover {
        color: #ffffff !important;
      }
    }
  }
</style>
