import axios from 'axios'
export default {
  state: {
    cost: [],
    courses: {
      "truck":{"id":"truck","title":"","treb":"","doc":"","term":"","car":"","doc_":"","cost":""},
      "truckD":{"id":"truckD","title":"","car":"","term":"","cost":""},
      "truckR":{
        "id":"truckR","title":"",
        "autodrome":{"title":"","car":"","cost":""},
        "city":{"title":"","car":"","cost":""}
      }
    }
  },
  mutations: {
    mutCost(state, payload) {
      state.cost = {}
      state.cost = payload
    },

    mutCourses(state, payload) {
      state.courses = []
      state.courses = payload
    }
  },
  actions: {
    async cost({commit, rootState}) {
      let response = await axios({
        url: `${rootState.host}/cost`,
        method: 'POST'
      })
      let result = await response
      commit('mutCost', result.data)
    },

    async updCost({commit, rootState}, payload) {
      let response = await axios({
        url: `${rootState.host}/updCost`,
        method: 'POST',
        data: payload
      })
      let result = await response
      commit('mutCost', result.data.cost)
      return result.data.updCost
    },

    async courses({rootState, commit}) {
      let response = await axios({
        url: `${rootState.host}/courses`,
        method: 'POST'
      })
      let result = await response
      commit('mutCourses', result.data)
    },

    async saveCourses({rootState, dispatch}, payload) {
      let response = await axios({
        url: `${rootState.host}/saveCourses`,
        method: 'POST',
        data: payload
      })
      let result = await response
      dispatch('courses')
      return result.data
    }
  },
  getters: {
    getCost: state => state.cost,
    getCourses: state => state.courses
  }
}